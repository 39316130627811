<template>
    <div>
        <!-- Start Preloader -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </div>
        <!-- End preloader -->
        <!-- Inner Banner Start-->
        <section class="inner-hero-banner">
            <div class="inner-hero-text"><br/><br/>
                <h1>Momentos Bávaros</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Momentos Bávaros</li>
                    </ol>
                </nav>
            </div>
        </section>
        <!-- Inner Banner End -->
        <!-- Start About Content Area -->
        <section class="section about-content-area no_padding">
            <div class="container">
                <div class="about-text-block wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="1.5s">
                    <div class="about-content">
                        <!-- <h4>
                            Momentos Bávaros
                        </h4><br /> -->
                        <h5>
                            Comparte tus mejores momentos en Merengón Bávaro
                        </h5><br />
                        <div class="text">Etiquétanos en Instagram @merengonbavaro  o Facebook @merengonbavaro91, con el hashtag  #UnMerengónCon #UnMerengonPara.</div><br />
                    </div>
                </div>
                <div class="video-grid">
                    <div v-for="videoId in videoIds" :key="videoId" class="video-wrapper">
                    <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + videoId" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Content Area -->
        <!-- Start About Countdown Area-->
        <section class="parallax parallax-box section">
            <div id="counter">
                <div class="container">
                    <div class="row">
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-instagram"></i></div>
                                <h3 id="number1" class="number counter-value" data-count="1422">0</h3>
                                <span></span>
                                <p>Seguidores en Instagram</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-owl"></i></div>
                                <h3 id="number2" class="number counter-value" data-count="16">0</h3>
                                <span></span>
                                <p>Especies de aves vistas desde nuestra casa</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-silverware-variant"></i></div>
                                <h3 id="number3" class="number counter-value" data-count="14">0</h3>
                                <span></span>
                                <p>Sabores Merengón</p>
                            </div>
                        </div>
                        <div class="col-md-3 col-6">
                            <div class="counter-box text-center">
                                <div class="counter-ico"><i class="mdi mdi-cake-variant"></i></div>
                                <h3 id="number4" class="number counter-value" data-count="32">0</h3>
                                <span></span>
                                <p>Años cumplidos</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Countdown Area-->
    </div>
</template>
<script>
    //WOW JS
    import { WOW } from 'wowjs';
    //ENLLAX JS
    import '../assets/js/jquery.enllax.min.js';
    export default {
        data() {
            return {
            videoIds: ['Wubcqqd66Nc', 'ReivT5Si8B4', 'a-K55JEG6xU']
            }
        },
        name: 'about',
        mounted() {
            this.init();
            window.scrollTo(0, 0);
        },
        methods: {
            init() {
                this.hidePreloader();
                this.initWowAnimation();
                this.initEnllax();
                this.windowScroll();
            },
            hidePreloader() {
                var preloaderFadeOutTime = 500;
                var preloader = $('.spinner-wrapper');
                setTimeout(function () {
                    preloader.fadeOut(preloaderFadeOutTime);
                }, 500);
            },
            /*----WOW ANIMATION-----*/
            initWowAnimation() {
                var length = $('.wow').length;
                if (length >= 1) {
                    const wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 0,
                    });
                    wow.init();
                }
            },
            /*----ENLLAX SCROLLING-----*/
            initEnllax() {
                $('.scroll-title').enllax();
            },
            /*----ONSCROLL JS-----*/
            windowScroll() {
                var self = this;
                $(window).on("scroll", function () {
                    self.initCounter();
                });
            },
            /*----COUNTER-----*/
            initCounter() {
                var length = $('#counter').length;
                if (length >= 1) {
                    var a = 0;
                    var oTop = $('#counter').offset().top - window.innerHeight;
                    if (a == 0 && $(window).scrollTop() > oTop) {
                        $('.counter-value').each(function () {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                            $({
                                countNum: $this.text()
                            }).animate({
                                countNum: countTo
                            }, {
                                duration: 2000,
                                easing: 'swing',
                                step: function () {
                                    $this.text(Math.floor(this.countNum));
                                },
                                complete: function () {
                                    $this.text(this.countNum);
                                }
                            });
                        });
                        a = 1;
                    }
                }
            }
        }
    }
</script>
<style scoped>
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}
.video-wrapper {
  width: 100%;
  padding-bottom: 56.25%; /* for 16:9 aspect ratio */
  position: relative;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.no_padding {
    padding-top: 0;
}
</style>